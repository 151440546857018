import React, { useState } from 'react';
import ReactModal from 'react-modal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import closeimg from '../img/close.svg';
import API_URL from '../config.js';
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const LoginModal = ({ isOpen, closeModal, openCodeModal, setPhoneNumber }) => {
    const [phone, setPhone] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handlePhoneInputChange = async (value) => {
        setPhone(value);
        if (value.length === 12) { // Длина номера телефона должна быть 12 символов включая код страны
            setIsSending(true);
            await handleSendCode(value);
            openCodeModal();
            closeModal();
            setIsSending(false);
        }
    };

    const handleSendCode = async (phone) => {
        const formattedPhone = phone.startsWith('+') ? phone.substring(1) : phone;
        try {
            await axios.patch(`${API_URL}/api/security/code/send`, { phone: formattedPhone });
            setPhoneNumber(formattedPhone);
        } catch (err) {
            console.error('Ошибка отправки кода:', err);
        }
    };

    return (
        <ReactModal isOpen={isOpen} onRequestClose={closeModal}>
            <h2 className='text-center'>Войдите или <br/> зарегистрируйтесь</h2>
            <PhoneInput
                country={'by'}
                value={phone}
                onChange={handlePhoneInputChange}
                placeholder="Введите номер телефона"
                disabled={isSending}
            />
            <div onClick={closeModal}>
                <img className='close-modal' src={closeimg} alt="Закрыть"/>
            </div>
            <p>Авторизуясь, вы соглашаетесь с <a className='color-blue' href='#'>Пользовательским соглашением</a> и <a className='color-blue' href='#'>Политикой конфиденциальности</a></p>
        </ReactModal>
    );
};

export default LoginModal;