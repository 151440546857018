import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Header from './components/Header'; // Импортируем компонент Header
import FooterMenu from './components/FooterMenu';
import Login from './pages/Login';
import Categories from './pages/Categories';
import CategoryPage from './pages/CategoryPage';
import Profile from './pages/Profile';
import Subscription from './pages/Subscription';
import Company from './pages/Company';
import About from './pages/About';
import Balance from './pages/Balance';
import LoginModal from './components/LoginModal';
import CodeModal from './components/CodeModal';
import axios from 'axios';
import API_URL from './config'; // Импортируем API_URL

const App = () => {
  const [categories, setCategories] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [user, setUser] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countriesResponse, categoriesResponse, subscriptionsResponse] = await Promise.all([
          axios.get(`${API_URL}/api/countries`),
          axios.get(`${API_URL}/api/city/1/categories`), // замените на актуальный cityId
          axios.get(`${API_URL}/api/subscription/plans`),
        ]);

        setCountries(countriesResponse.data.countries);
        setCategories(categoriesResponse.data.categories);
        setSubscriptions(subscriptionsResponse.data.items);
      } catch (error) {
        console.error("Ошибка при загрузке данных: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (token) {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/profile`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUser(response.data);
        } catch (error) {
          console.error("Ошибка при загрузке профиля: ", error);
        }
      };
      fetchProfile();
    }
  }, [token]);

  useEffect(() => {
    if (selectedCityId) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(`${API_URL}/api/city/${selectedCityId}/categories`);
          setCategories(response.data.categories);
        } catch (error) {
          console.error("Ошибка при загрузке категорий: ", error);
        }
      };
      fetchCategories();
    }
  }, [selectedCityId]);

  const handleLogin = (authToken) => {
    setIsAuthenticated(true);
    setToken(authToken);
    localStorage.setItem('token', authToken); // Сохраняем токен в локальное хранилище
    setIsCodeModalOpen(false); // Закрыть модальное окно с вводом кода после успешного входа
    navigate('/profile');
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const openCodeModal = () => {
    setIsCodeModalOpen(true);
  };

  const closeCodeModal = () => {
    setIsCodeModalOpen(false);
  };

  return (
      <div className="container">
        <Routes>
          <Route path="/" element={
            <>
              <Header title="Главная" subtitle="Главная страница" />
              <Main categories={categories} />
            </>
          } />
          <Route path="/categories" element={
            <>
              <Header title="Категории" subtitle="Все категории" />
              <Categories categories={categories} />
            </>
          } />
          <Route path="/categories/:categoryId" element={
            <>
              <Header title="Категория" subtitle="Страница категории" />
              <CategoryPage />
            </>
          } />
          <Route path="/profile" element={
            <>
              <Header title="Профиль" subtitle="Профиль пользователя" />
              <Profile user={user} countries={countries} setSelectedCityId={setSelectedCityId} />
            </>
          } />
          <Route path="/subscription" element={
            <>
              <Header title="Подписка" subtitle="Ваши подписки" />
              <Subscription subscriptions={subscriptions} />
            </>
          } />
          <Route path="/company/:productId" element={
            <>
              <Header title="Компания" subtitle="Страница компании" />
              <Company />
            </>
          } />
          <Route path="/about" element={
            <>
              <Header title="Информация о сервисе" subtitle="Что такое Sила?" />
              <About />
            </>
          } />
          <Route path="/balance" element={
            <>
              <Header title="Баланс" subtitle="Ваш баланс" />
              <Balance user={user} />
            </>
          } />
        </Routes>

        <FooterMenu isAuthenticated={isAuthenticated} openLoginModal={openLoginModal} />

        <LoginModal
            isOpen={isLoginModalOpen}
            closeModal={closeLoginModal}
            openCodeModal={openCodeModal}
            setPhoneNumber={setPhoneNumber}
        />
        <CodeModal
            isOpen={isCodeModalOpen}
            closeModal={closeCodeModal}
            phoneNumber={phoneNumber}
            handleLogin={handleLogin}
        />
      </div>
  );
};

const Main = ({ categories }) => {
  return (
      <div className="category-index">
        <div className="category-index-item">
          <h3>Все Категории</h3>
          {categories.length > 0 ? (
              <div id="categories_block">
                {categories.map(category => (
                    <div key={category.id} className="category-item">
                      <Link to={`/categories/${category.id}`}>
                        <img src={category.icon} alt={category.name} />
                        <p>{category.name}</p>
                      </Link>
                    </div>
                ))}
              </div>
          ) : (
              <p>Нет доступных категорий.</p>
          )}
        </div>

        {categories.length > 0 ? (
            <div className="block">
              {categories.map(category => (
                  <div className="category-index-item" key={category.id}>
                    <h3>{category.name}</h3>
                    <div className="companies-block">
                      {category.companies.map(company => (
                          <div key={company.id} className="max-benefit-item">
                            <Link to={`/company/${company.id}`}>
                              <div className="background-company-item" style={{ backgroundImage: `url(${company.logo})` }}></div>
                              <p>{company.name}</p>
                              <p className='sila-item molniya-after'>10</p>
                            </Link>
                          </div>
                      ))}
                    </div>
                  </div>
              ))}
            </div>
        ) : (
            <p>Нет доступных компаний.</p>
        )}
      </div>
  );
};

export default App;