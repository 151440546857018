import React, { useState } from 'react';
import axios from 'axios';
import API_URL from '../config.js';
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Login = ({ handleLogin }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${API_URL}/api/security/code/send`;

        try {
            const response = await axios.patch(url, { phone: phoneNumber });
            const token = response.data.token;
            handleLogin(token);
            setMessage('Код отправлен успешно!');
        } catch (error) {
            setMessage(`Ошибка при отправке кода: ${error.response.data}`);
        }
    };

    return (
        <div>
            <h1>Вход по номеру телефона</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Введите номер телефона"
                    required
                />
                <button type="submit">Отправить код</button>
            </form>
            {message && <div className="message">{message}</div>}
        </div>
    );
};

export default Login;