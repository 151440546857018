import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config';

const getCompanyWord = (num) => {
    if (num === 1) return 'компания';
    if (num >= 2 && num < 5) return 'компании';
    return 'компаний';
};

const CategoryPage = () => {
    const { categoryId } = useParams();
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/category/${categoryId}`);
                setCategory(response.data);
                setLoading(false);
            } catch (error) {
                setError('Ошибка при загрузке данных категории');
                setLoading(false);
            }
        };

        fetchCategoryData();
    }, [categoryId]);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!category) {
        return <div>Категория не найдена</div>;
    }

    if (category.subCategories && category.subCategories.length > 0) {
        return (
            <div className="category-page">
                <h2 className='text-center'>Категория {category.name}</h2>
                    {category.subCategories.map(subcategory => (
                        <div className="category-root">
                            <div className='category-header' key={subcategory.id}>
                                <Link to={`/categories/${subcategory.id}`}>
                                    <p>{subcategory.name}</p>
                                    <p>{category.companyCount} {getCompanyWord(category.companyCount)}</p>
                                </Link>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }

    return (
        <div className="category-page">
            <div className='category-tittle-header'>
                <h2 className='text-center border-0 m-0'>Категория {category.name}</h2>
                <p className='text-center m-0 subtittle-font'>{category.companyCount} {getCompanyWord(category.companyCount)}</p>
            </div>
            <div className="companies-block">
                {category.companies.map(company => (
                    <div key={company.id} className="max-benefit-item">
                        <Link to={`/company/${company.id}`}>
                            <div className="background-company-item" style={{ backgroundImage: `url(${company.logo})` }}></div>
                            <p>{company.name}</p>
                            <p className='sila-item molniya-after'>{company.minMaxPowerCountByType[0].maxPowerCount}</p>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoryPage;