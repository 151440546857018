import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../components/Company.css';
import API_URL from '../config.js';
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Company = () => {
    const { productId } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/company/${productId}`);
                setCompany(response.data);
                setLoading(false);
            } catch (error) {
                setError('Ошибка загрузки данных компании');
                setLoading(false);
            }
        };

        fetchCompany();
    }, [productId]);

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!company) {
        return <div>Нет данных о компании</div>;
    }

    return (
        <div className="company-container">

            <img src={company.logo} alt={company.name} className="company-logo" />
            <h1>{company.name}</h1>
            <p>ИНН: {company.taxId}</p>
            <p>Адрес: {company.address}</p>
            <h2>Продукты</h2>
            <ul>
                {company.products.map(product => (
                    <li key={product.id}>
                        <p>{product.name}</p>
                        <p>Количество Power: {product.powerCount}</p>
                        <p>Цена: {product.price}</p>
                    </li>
                ))}
            </ul>
            <h2>Контактные телефоны</h2>
            <ul>
                {company.phones.map(phone => (
                    <li key={phone.number}>{phone.number}</li>
                ))}
            </ul>
            <h2>Филиалы</h2>
            <ul>
                {company.locations.map((location, index) => (
                    <li key={index}>
                        <p>Адрес: {location.address}</p>
                        <p>Телефоны:</p>
                        <ul>
                            {location.phones.map(phone => (
                                <li key={phone.number}>{phone.number}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Company;