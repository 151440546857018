import React from 'react';

const Balance = ({ user }) => {
    return (
        <div>
            <h1>Баланс</h1>
            {user && (
                <div>
                    <p>Количество Power: {user.subscription?.numberOfPower}</p>
                    <p>Статус подписки: {user.subscription?.status === 0 ? 'Не активирована' : 'Активирована'}</p>
                </div>
            )}
            {!user && <p>Требуется вход в систему для просмотра информации о балансе.</p>}
        </div>
    );
}

export default Balance;