import React from 'react';

const Subscription = ({ subscriptions }) => {
    return (
        <div>
            <h1>Подписка</h1>
            {subscriptions.length > 0 ? (
                <ul>
                    {subscriptions.map(subscription => (
                        <li key={subscription.id}>
                            <h2>{subscription.name}</h2>
                            <p>{subscription.description}</p>
                            <p>Цена: {subscription.price} за {subscription.period} дней</p>
                            <p>Количество Power: {subscription.powerCount}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Нет доступных подписок.</p>
            )}
        </div>
    );
}

export default Subscription;