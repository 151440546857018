import React from 'react';
import { Link } from 'react-router-dom';
import arrowImage from '../img/arrow.svg'

const Header = ({ title, subtitle }) => {
    return (
        <header className="header">
            <Link to="/">
                <img src={arrowImage} alt="Logo" className="logo" />
            </Link>
            <div className="page-info">
                <h1>{title}</h1>
                {subtitle && <p>{subtitle}</p>}
                <nav className="nav">
                    <Link to="/categories" className="nav-link">Категории</Link>
                    <Link to="/profile" className="nav-link">Профиль</Link>
                    <Link to="/about" className="nav-link">Информация</Link>
                    <Link to="/balance" className="nav-link">Баланс</Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;