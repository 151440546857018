import React from 'react';
import { useNavigate } from 'react-router-dom';

const getCompanyWord = (num) => {
  if (num === 1) return 'компания';
  if (num >= 2 && num < 5) return 'компании';
  return 'компаний';
};

const CategoryMenu = ({ categories }) => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/categories/${category.id}`);
  };

  return (
      <div className="category-menu">
        {categories.map((category) => (
            <div key={category.id} className="category-root" onClick={() => handleCategoryClick(category)}>
              <div className="category-header">
                <div>
                  <img src={category.icon} alt={category.name} className="category-icon" />
                </div>
                <div>
                  <p>{category.name}</p>
                  <p>{category.companyCount} {getCompanyWord(category.companyCount)}</p>
                </div>
              </div>
            </div>
        ))}
      </div>
  );
};

export default CategoryMenu;