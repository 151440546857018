import React from 'react';
import { NavLink } from 'react-router-dom';

import logo_categories from '../img/categories.svg'
import logo_profile from '../img/profile.svg'
import logo_about from '../img/about.svg'
import logo_sila from '../img/sila.svg'

const FooterMenu = ({ isAuthenticated, openLoginModal }) => {
    return (
        <footer className="footer-menu">
            <NavLink to="/categories">
                <div className="footer-item">
                    <img src={logo_categories}/>
                    <p className="footer-link" to="/categories">Категории</p>
                </div>
            </NavLink>
            {isAuthenticated ? (
                <NavLink to="/profile">
                    <div className="footer-item">
                        <img src={logo_profile} alt="Профиль" />
                        <p className="footer-link">Профиль</p>
                    </div>
                </NavLink>
            ) : (
                <div className="footer-link clickable" onClick={openLoginModal}>
                    <div className="footer-item">
                        <img src={logo_profile} alt="Войти" />
                        <p className="footer-link">Войти</p>
                    </div>
                </div>
            )}
            <NavLink to="/about">
                <div className="footer-item">
                    <img src={logo_about}/>
                    <p className="footer-link" to="/about">О сервисе</p>
                </div>
            </NavLink>
            <NavLink to="/balance">
                <div className="footer-item">
                <img src={logo_sila}/>
                    <p className="footer-link">Силы</p>
                </div>
            </NavLink>
        </footer>
    );
}

export default FooterMenu;