import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config.js';
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Profile = ({ user, countries, setSelectedCityId }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Токен не найден');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/api/profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setProfileData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Ошибка загрузки данных профиля');
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    if (user) {
      console.log(user.subscription);
    }
  }, [user]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedCityId(event.target.value);
  };

  const cities = selectedCountry
      ? countries.find((country) => country.id === parseInt(selectedCountry)).cities
      : [];

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!profileData) {
    return <div>Нет данных профиля</div>;
  }

  return (
      <div>
        <h1>Профиль</h1>
        <p>Информация о профиле пользователя будет здесь...</p>

        <p>Номер телефона: {profileData.phone}</p>
        <div>
          {profileData.subscription !== null ? `<p>Подписка: {profileData.subscription.plan.name}</p>
          <p>Цена: {profileData.subscription.plan.price}</p>
          <p>Период: {profileData.subscription.plan.period} дней</p>
          <p>Количество Power: {profileData.subscription.numberOfPower}</p>
          <p>
            Статус: {profileData.subscription.status === 0 ? 'Не активирован' : 'Активирован'}
          </p>` : ''
          }

        </div>

        <div>
          <label htmlFor="country-select">Выберите страну:</label>
          <select id="country-select" value={selectedCountry} onChange={handleCountryChange}>
            <option value="">--Выберите страну--</option>
            {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
            ))}
          </select>
        </div>

        {selectedCountry && (
            <div>
              <label htmlFor="city-select">Выберите город:</label>
              <select id="city-select" value={selectedCity} onChange={handleCityChange}>
                <option value="">--Выберите город--</option>
                {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                ))}
              </select>
            </div>
        )}
      </div>
  );
};

export default Profile;