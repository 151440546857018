import React from 'react';
import CategoryMenu from '../components/CategoryMenu';
import closeimg from "../img/close.svg";
import { Link } from 'react-router-dom';
import '../components/Category.css';

const Categories = ({ categories }) => {
    return (
        <div className="category-page">
            <h2 className="text-center">Категории</h2>
            <Link to="/">
                <img className="close-modal" src={closeimg} alt="Закрыть" />
            </Link>
            {categories.length > 0 ? (
                <CategoryMenu categories={categories} />
            ) : (
                <p>Нет доступных категорий.</p>
            )}
        </div>
    );
};

export default Categories;