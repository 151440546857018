import React from "react";

const About = () => {
    return (
        <div className="about-page">
            <h2 className='text-center'>Информация о сервисе</h2>

            <h2>Что такое "Sила"?</h2>
            <p>
                "Sила" — это виртуальная единица, эквивалентная 1 рублю, которую вы можете использовать для получения скидок на товары и услуги в рамках нашего приложения, при покупке у партнеров сервиса.
            </p>

            <h2>Как я могу получить Sилу?</h2>
            <p>
                Есть несколько способов получить Sилу:
                <ul>
                    <li>оформить Тариф на Безлимит Sилы, перейти на страницу понравившейся компании и нажать кнопку “Получить Sилу тут”. Вуа-ля, вы приобрели Sилу.</li>
                    <li>предоплатить услугу онлайн, выбрать нужный размер Sилы, нажать кнопку “Оплатить онлайн”. При обращении к партнеру, сообщить о том, что вы уже оплатили чек на N сумму.</li>
                </ul>
            </p>

            <h2>Как я могу использовать Sилу?</h2>
            <p>
                При оформлении заказа по телефону или в заведении у партнера, сообщите о своем желании воспользоваться Sилой. При оформлении заказа онлайн на сайте или в приложении партнера, введите код Sилы в соответствующее поле (Код, Промокод, Скидка). Sила будет вычтена из суммы чека, что позволит вам получить скидку на покупку.
            </p>

            <h2>Можно ли обменять Sилу на деньги?</h2>
            <p>
                Нет, Sила является виртуальной единицей, которую можно использовать только для получения скидок при оплате товаров и услуг. Обменить Sилу на денежные средства нельзя.
            </p>

            <h2>Есть ли ограничения на использование Sилы?</h2>
            <p>
                Да, условия и ограничения могут зависеть от конкретных товаров или услуг, а также от акций, проводимых партнерами приложения. Пожалуйста, ознакомьтесь с условиями каждой компании перед преобретением и использованием Sилы.
            </p>

            <h2>Какой срок действия Sилы?</h2>
            <p>
                Срок действия Sилы может варьироваться в зависимости от условий компании. Вы сможете ознакомиться со срокам действия Sилы на странице партнера сервиса.
            </p>

            <h2>Что делать, если у меня возникли проблемы с использованием Sилы?</h2>
            <p>
                Если у вас возникли трудности или вопросы, пожалуйста, обратитесь в службу поддержки по номеру +375 (29) 608-22-22 (Viber, Telegram).
            </p>
        </div>
    );
};

export default About;