import React, { useState, useRef } from 'react';
import ReactModal from 'react-modal';
import axios from 'axios';
import closeimg from "../img/close.svg";

import API_URL from '../config.js';
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const CodeModal = ({ isOpen, closeModal, phoneNumber, handleLogin }) => {
    const [code, setCode] = useState(['', '', '', '']);
    const inputRefs = useRef([React.createRef(), React.createRef(), React.createRef(), React.createRef()]);

    const handleInputChange = (value, index) => {
        const newCode = [...code];
        newCode[index] = value.slice(-1); // Ensure only one character

        setCode(newCode);

        if (value && index < 3) {
            inputRefs.current[index + 1].current.focus();
        }

        if (newCode.every((digit) => digit !== '') && newCode.length === 4) {
            const completeCode = newCode.join('');
            handleSubmit(completeCode);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1].current.focus();
        }
    };

    const handleSubmit = async (code) => {
        try {
            const response = await axios.post(`${API_URL}/api/login`, { phone: phoneNumber, password: code });
            const token = response.data.token;
            handleLogin(token);
            closeModal();
        } catch (err) {
            console.error('Ошибка авторизации:', err);
        }
    };

    return (
        <ReactModal isOpen={isOpen} onRequestClose={closeModal}>
            <p className='text-center tittle-block'>Проверка</p>
            <p className='description-block text-center m-0'>Введите код</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '22px', margin: '31px 0' }}>
                {code.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        value={digit}
                        maxLength={1}
                        ref={inputRefs.current[index]}
                        onChange={(e) => handleInputChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className='code-input'
                    />
                ))}
            </div>
            <div onClick={closeModal}>
                <img className='close-modal' src={closeimg} alt="Закрыть"/>
            </div>
            <p>Авторизуясь, вы соглашаетесь с <a className='color-blue' href='#'>Пользовательским соглашением</a> и <a className='color-blue' href='#'>Политикой конфиденциальности</a></p>
        </ReactModal>
    );
};

export default CodeModal;